<template>
  <div id="SideBar">
    <el-menu
      :default-active="activeRouter"
      class="el-menu-vertical-demo"
      :collapse="isCollapse"
      background-color="#231815"
      text-color="#ffffff"
      router
      active-text-color="#ffd04b"
    >
      <!-- width:22px;height:22px; -->
      <el-menu-item index="/PersonalCenter/PersonalDatum">
        <img src="~@/assets/img/Slice 21.png" alt="" />
        <span slot="title">用户资料</span>
      </el-menu-item>
      <el-menu-item index="/PersonalCenter/SetPassword">
        <img src="~@/assets/img/Slice 22.png" alt="" />
        <span slot="title">密码设置</span>
      </el-menu-item>
      <el-menu-item index="/PersonalCenter/Information">
        <img src="~@/assets/img/Slice 23.png" alt="" />
        <span slot="title">消息中心</span>
      </el-menu-item>

      <div class="icon-box">
        <img v-show="!isCollapse" src="~@/assets/img/Slice 9.png" alt="" @click="showList" />
        <img v-show="isCollapse" src="~@/assets/img/Slice 15.png" alt="" @click="showList" />
      </div>
    </el-menu>

    <!-- <main>
      <nav>
        <ul class="sidebar">
          <li>
            <router-link :to="'/PersonalCenter/PersonalDatum'" :active-class="'active'">
              用户资料
            </router-link>
          </li>
          <li>
            <router-link :to="'/PersonalCenter/SetPassword'" :active-class="'active'">
              密码设置
            </router-link>
          </li>
          <li>
            <router-link :to="'/PersonalCenter/Information'" :active-class="'active'">
              消息中心
            </router-link>
          </li>
        </ul>
      </nav>
    </main> -->
  </div>
</template>

<script>
export default {
  name: 'SideBar',
  data() {
    return {
      isCollapse: false,
      activeRouter: '',
    };
  },
  created() {
    this.activeRouter = this.$router.history.current.fullPath;
  },
  methods: {
    showList() {
      this.isCollapse = !this.isCollapse;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-box {
  cursor: pointer;
  position: absolute;
  bottom: 30px;
  left: 15px;
  img {
    width: 26px;
    height: 26px;
  }
}
.el-submenu__title {
  img {
    width: 22px;
    height: 22px;
    margin-right: 10px;
  }
}
.el-menu-item {
  img {
    width: 22px;
    height: 22px;
    margin-right: 10px;
  }
}
// nav {
//   width: 10vw;
//   height: 88vh;
//   background: #1e222d;
//   padding: 2vh 0;
//   .sidebar li > a {
//     width: 10vw;
//     height: 50px;
//     line-height: 50px;
//     display: block;
//     color: #ffffff;
//     text-align: center;
//     text-decoration: none;
//     cursor: pointer;
//   }
//   .sidebar li > a:hover {
//     background: #f7b515;
//   }
// }
// .active {
//   width: 10vw;
//   height: 50px;
//   line-height: 50px;
//   display: block;
//   text-align: center;
//   text-decoration: none;
//   cursor: pointer;
//   background: #f7b515;
// }
</style>
<style>
.el-menu-item.is-active {
  border-left: 4px solid #f7b515;
  color: #f7b515;
  background: rgba(255, 255, 255, 0.3) !important;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: calc(100vh - 62px);
}
.el-menu--collapse {
  min-height: calc(100vh - 62px);
}
</style>
