<template>
  <div class="PersonalCenter">
    <side-bar class="side"></side-bar>
    <div class="Big-box">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import SideBar from './SideBar';

export default {
  name: 'PersonalCenter',
  components: {
    SideBar,
  },
};
</script>

<style lang="scss" scoped>
.PersonalCenter {
  width: 100%;
  height: 92vh;
  margin: auto;
  display: flex;
}
.side {
  width: 10vw;
}
.Big-box {
  width: 90vw;
  padding: 20px 20px;
  overflow: auto;
  flex-wrap: wrap;
}
</style>
